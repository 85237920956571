<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 class="overlay">
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            {{ title }}
            <v-spacer></v-spacer>
            <!-- <add-button
              :permission="true"
              @action="content_dialogue = true"
              v-if="type_id == 1"
            >
              Add Content
            </add-button> -->
          </v-card-title>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="templates"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  {{ ++index }}
                </td>
                <td class="text-xs-left">
                  {{ item.name }} &nbsp;
                  <v-chip
                    small
                    color="primary"
                    label
                    v-if="type_id == 1"
                    @click="listCertificateContent = true"
                    >{{ certificate_contents.length }} content</v-chip
                  >
                </td>
                <td class="text-right">
                  <add-button
                    :permission="true"
                    @action="
                      (content_dialogue = true),
                        (certificateContent.company_template_id =
                          item.company_template_id)
                    "
                    v-if="type_id == 1"
                  >
                    Add Content
                  </add-button>

                  <edit-button
                    :permission="true"
                    @agree="form.edit(item), getCertificateContent()"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" scrollable persistent max-width="820px">
        <v-card outlined class="primary--border">
          <v-card-title class="primary white--text">
            <span class="title">Customize Template</span>
          </v-card-title>
          <v-card-text>
            <br />
            <alert-message
              title="Template Customization"
              :type="'warning'"
              extra-small
              message="Please be aware that any modifications in the current settings may alter your template design. Contact system admininstration for more info."
            ></alert-message>
            <br />
            <v-form ref="form">
              <div class="mt-2" v-if="form.settings && form.settings != ''">
                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="off"
                      label="Template name"
                      required
                      class="pa-0 mb-0"
                      outlined
                      dense
                      v-model="form.name"
                      name="name"
                      disabled
                      :error-messages="form.errors.get('name')"
                      :rules="[(v) => !!v || 'Name is required']"
                    />
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  mt-10
                  v-if="type_id == 2 && display_card_size"
                >
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span style="padding: 0 10px; color: #c1c1c1"
                    >Card Heignt / Width Settings</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>
                <br />
                <alert-message
                  v-if="type_id == 2 && display_card_size"
                  title="Card Size Recommendation"
                  type="warning"
                  extra-small
                  message="Card Height should not be greater than 307.5 "
                ></alert-message>
                <br />
                <v-row v-if="type_id == 2 && display_card_size">
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.card_size.height"
                      autocomplete="off"
                      label="Card height"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="card_height"
                    />
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.card_size.width"
                      autocomplete="off"
                      label="Card width"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="card_width"
                      disabled
                    />
                  </v-col>
                </v-row>

                <v-row align="center" mt-10 v-if="type !== 'CERTIFICATE'">
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span style="padding: 0 10px; color: #c1c1c1"
                    >Content Settings</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>
                <v-row v-if="type !== 'CERTIFICATE'">
                  <v-col sm="12" md="12">
                    <v-select
                      v-model="form.form_body"
                      :items="
                        type_id === 2 ? content_body : entrance_content_body
                      "
                      label="Select template's body content"
                      multiple
                      class="pt-0 mb-0"
                      outlined
                      dense
                    >
                      <v-list-item slot="prepend-item" ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="content_body > 0 ? 'indigo darken-4' : ''"
                            >{{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All </v-list-item-title>
                      </v-list-item>
                      <v-divider slot="prepend-item" class="mt-2"></v-divider>
                      <v-divider slot="append-item" class="mb-2"></v-divider>
                    </v-select>
                  </v-col>
                  <v-col sm="12" md="3">
                    <v-text-field
                      autocomplete="off"
                      v-model="form.settings.card_body.content_size"
                      label="Content Size"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="cotent_size"
                    />
                  </v-col>
                  <v-col sm="12" md="3" v-if="type_id == 2">
                    <v-text-field
                      autocomplete="off"
                      v-model="form.settings.card_body.title_size"
                      label="Title Size"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="title_size"
                    />
                  </v-col>
                  <v-col md="3">
                    <v-select
                      v-model="form.content_weight"
                      class="pa-0 mb-0"
                      autocomplete="off"
                      :items="content_weight"
                      label="Content Weight"
                      outlined
                      dense
                    ></v-select>
                  </v-col>

                  <v-col md="3">
                    <v-text-field
                      disabled
                      autocomplete="off"
                      label="Margin Top."
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="image_height"
                    />
                  </v-col>
                  <v-col md="3">
                    <v-text-field
                      disabled
                      autocomplete="off"
                      label="Margin Bottom."
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="image_height"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row align="center">
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span
                    v-if="type_id == 2"
                    style="padding: 0 10px; color: #c1c1c1"
                    >Picture / Logo Settings / Scan Type Settings</span
                  >
                  <span v-else style="padding: 0 10px; color: #c1c1c1"
                    >Picture / Logo Settings</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>
                <br />
                <alert-message
                  title="Image Recommendation"
                  :type="
                    warning_type == 'success' && image_warning_type == 'success'
                      ? 'success'
                      : 'warning'
                  "
                  extra-small
                  message="Logo and user's profile picture are usually square in size, hence we recommend to define the size with same aspect ratio "
                ></alert-message>
                <br />
                <v-row>
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.image_size.height"
                      autocomplete="off"
                      label="Picture height"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="image_height"
                      @input="changeImageDetech()"
                    />
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.image_size.width"
                      autocomplete="off"
                      label="Picture width"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="image_width"
                      @input="changeImageDetech()"
                    />
                  </v-col>
                  <v-col v-if="type_id == 2 || type_id == 1" sm="12" md="4">
                    <v-text-field
                      v-model="form.logo_size.height"
                      autocomplete="off"
                      label="Logo height"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="logo_height"
                      @input="changeDetect()"
                    />
                  </v-col>
                  <v-col v-if="type_id == 2 || type_id == 1" sm="12" md="4">
                    <v-text-field
                      v-model="form.logo_size.width"
                      autocomplete="off"
                      label="Logo width"
                      class="pa-0"
                      outlined
                      dense
                      type="number"
                      name="logo_width"
                      @input="changeDetect()"
                    />
                  </v-col>
                  <v-col v-if="type_id == 1" sm="12" md="4">
                    <v-select
                      autocomplete="off"
                      :items="logo_position"
                      label="Select Logo Position"
                      outlined
                      dense
                      class="pa-0"
                      v-model="form.logo_position"
                    ></v-select>
                  </v-col>
                  <v-col v-if="type_id == 2" sm="12" md="4">
                    <v-select
                      autocomplete="off"
                      :items="scan_type"
                      label="Select Scan Type"
                      outlined
                      dense
                      class="pa-0"
                      v-model="form.scan_type"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col sm="12" md="4">
                    here
                  </v-col>
                </v-row> -->

                <br />
                <br />
                <v-row align="center" mt-10 v-if="type_id === 2">
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span style="padding: 0 10px; color: #c1c1c1"
                    >Color Settings</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>
                <v-row v-if="type_id === 2">
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.color.primary"
                      autocomplete="off"
                      label="Primary Color"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="text"
                      name="primary_color"
                    />
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.color.secondary"
                      autocomplete="off"
                      label="Secondary Color"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="text"
                      name="secondary_color"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row align="center" mt-10>
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span style="padding: 0 10px; color: #c1c1c1"
                    >Header / Footer Settings</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>

                <v-row>
                  <v-col sm="12" md="4">
                    <v-text-field
                      v-model="form.settings.heading_size"
                      autocomplete="off"
                      label="School's name (font size)"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="header_size"
                    />
                  </v-col>
                  <v-col sm="12" md="4">
                    <v-text-field
                      v-model="form.settings.contact_info_font_size"
                      autocomplete="off"
                      label="Contact info (font Size)"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="contact_info_font_size"
                    />
                  </v-col>
                  <v-col sm="12" md="4">
                    <v-text-field
                      v-model="form.settings.slogan_font_size"
                      autocomplete="off"
                      label="Slogan (font Size)"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="contact_info_font_size"
                    />
                  </v-col>
                  <v-col sm="12" md="12" v-if="type_id == 3">
                     <v-select
                      autocomplete="off"
                      :items="entrance_cards_display"
                      label="Select Entrance Card Design"
                      outlined
                      dense
                      class="pa-0"
                      v-model="form.display_entrance_card"
                    ></v-select>
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-select
                      class="mb-0"
                      dense
                      v-model="form.selected"
                      :items="signature"
                      outlined
                      label="Authorized Signature for template"
                      multiple
                    >
                      <v-list-item
                        slot="prepend-item"
                        ripple
                        @click="toogleSignature"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="signature > 0 ? 'indigo darken-4' : ''"
                            >{{ signatureIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-divider slot="prepend-item" class="mt-2"></v-divider>
                      <v-divider slot="append-item" class="mb-2"></v-divider>
                    </v-select>
                  </v-col>
                  <!-- Footer Option -->
                  <v-col sm="12" md="12" v-if="type_id == 1">
                    <v-select
                      class="mb-0"
                      dense
                      v-model="form.certificate_footer_options"
                      :items="footer_options"
                      outlined
                      label="Select Footer Option"
                      multiple
                    >
                      <v-list-item
                        slot="prepend-item"
                        ripple
                        @click="toogleFooterOption"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="footer_options > 0 ? 'indigo darken-4' : ''"
                            >{{ footerOptionIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-divider slot="prepend-item" class="mt-2"></v-divider>
                      <v-divider slot="append-item" class="mb-2"></v-divider>
                    </v-select>
                  </v-col>
                  <!-- Footer Option End -->
                  <!-- Signature Settings -->
                  <v-col sm="12" md="4" v-if="type_id == 2">
                    <v-text-field
                      autocomplete="off"
                      label="Signature Top"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="signature_top"
                      v-model="form.signature_setting.signature_top"
                    />
                  </v-col>
                  <v-col sm="12" md="4" v-if="type_id == 2">
                    <v-text-field
                      autocomplete="off"
                      label="Signature Left"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="signature_left"
                      v-model="form.signature_setting.signature_left"
                    />
                  </v-col>
                  <v-col sm="12" md="4" v-if="type_id == 2">
                    <v-text-field
                      autocomplete="off"
                      label="Signature Width"
                      class="pa-0 mb-0"
                      outlined
                      dense
                      type="number"
                      name="signature_width"
                      v-model="form.signature_setting.signature_width"
                    />
                  </v-col>
                  <!-- signaute setting end here -->

                  <v-col sm="12" md="12" v-if="type_id == 3">
                    <v-textarea
                      rows="2"
                      outlined
                      name="note"
                      label="Note"
                      v-model="form.note"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    sm="12"
                    md="12"
                    v-if="
                      certificate_dropdown_contents.length > 0 && type_id === 1
                    "
                  >
                    <!-- :items="[{ text: 'Default', value: '' } , ...certificate_dropdown_contents]" -->
                    <v-select
                      autocomplete="off"
                      :items="certificate_dropdown_contents"
                      label="Select Certificate Content"
                      outlined
                      dense
                      class="pa-0"
                      v-model="certificateContent.id"
                    ></v-select>
                  </v-col>
                  <!-- font type  -->
                  <v-col sm="12" md="12" v-if="type_id === 1">
                    <v-select
                      class="pa-0"
                      autocomplete="off"
                      :items="font_family"
                      label="Select Font Style"
                      outlined
                      dense
                      v-model="form.font_family"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- </div > -->
                <!-- v-if="certificate_dropdown_contents.length > 0" -->
                <v-template-creator-field
                  v-show="
                    type_id == 1 && certificate_dropdown_contents.length > 0
                  "
                  :rows="8"
                  :show-alert="false"
                  v-model="form.content"
                  label="Certificate Content"
                  :default-placeholder="[
                    {
                      text: 'Student Name',
                      placeholder: '[[student_name]]',
                    },
                    {
                      text: 'Father Name',
                      placeholder: '[[father_name]]',
                    },
                    {
                      text: 'Mother Name',
                      placeholder: '[[mother_name]]',
                    },
                    {
                      text: 'District',
                      placeholder: '[[district]]',
                    },
                    {
                      text: 'Address',
                      placeholder: '[[address]]',
                    },
                    {
                      text: 'Ward No',
                      placeholder: '[[ward_no]]',
                    },
                    {
                      text: 'From Date',
                      placeholder: '[[from_date]]',
                    },
                    {
                      text: 'To Date',
                      placeholder: '[[to_date]]',
                    },
                    {
                      text: 'Grade',
                      placeholder: '[[grade]]',
                    },
                    {
                      text: 'GPA',
                      placeholder: '[[gpa]]',
                    },
                    {
                      text: 'Exam Date BS',
                      placeholder: '[[exam_date_BS]]',
                    },
                    {
                      text: 'Exam Date AD',
                      placeholder: '[[exam_date_AD]]',
                    },
                    {
                      text: 'Date Of Birth(BS)',
                      placeholder: '[[date_of_birth_BS]]',
                    },
                    {
                      text: 'Date Of Birth(AD)',
                      placeholder: '[[date_of_birth_AD]]',
                    },
                    {
                      text: 'Character',
                      placeholder: '[[character]]',
                    },
                    {
                        text: 'Religion',
                        placeholder: '[[religion]]',
                      },
                      {
                        text: 'Province',
                        placeholder: '[[province]]',
                      },
                  ]"
                  :default-content="form.content"
                ></v-template-creator-field>
                <v-spacer></v-spacer>

                <br />
                <br />
                <v-row align="center" mt-10>
                  <v-divider></v-divider>&nbsp;&nbsp;
                  <span style="padding: 0 10px; color: #c1c1c1"
                    >More Settings and Preferences</span
                  >
                  &nbsp;&nbsp;<v-divider></v-divider>
                </v-row>

                <v-row v-if="form.settings != ''">
                  <v-col sm="4" v-if="type_id == 2 || type_id == 1">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.display_contact_info"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Contact Info
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck if you want your school's contact
                              information hidden.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col sm="4" v-if="type_id == 2 || type_id == 1">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.display_registraion"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Registration No.
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck to remove the registration number field
                              from certficate
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col sm="4">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.display_slogan"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display School's Slogan
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck to hide school's slogan from the header.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <!-- <v-col sm="4" v-if="type_id == 2">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.show_bar_code"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Barcode
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck Hide barcode scanner from id card
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col> -->
                  <v-col sm="4" v-if="type_id == 2">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.show_guardian_info"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Guardian Information
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck to remove student's guardian information
                              for ID card.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col sm="4" v-if="type_id == 2">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.add_valid_till"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Validity
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck to remove valid till date from ID card.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <!-- <v-col sm="4" v-if="type_id == 2">
                    <v-checkbox
                      class="mb-0"
                      style="margin-right: 10px; margin-bottom: 0"
                      color="blue"
                      v-model="form.settings.display_guardian_number"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Guardian Number
                          <p style="line-height: 10px; padding-top: 3px">
                            <small
                              style="
                                color: #999;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.1;
                                padding-top: 15px;
                              "
                            >
                              Uncheck to remove Guardian Number from ID card.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col> -->
                
                  <v-col sm="4" v-if="type_id == 1">
                    <v-checkbox
                      v-model="form.settings.display_established_year"
                      label="Display Established Year"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col sm="4" v-if="type_id == 1">
                    <v-checkbox
                      v-model="form.is_default"
                      label="Set as Default"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col sm="4" v-if="type_id == 1">
                    <v-checkbox
                      v-model="form.settings.display_signature"
                      label="Display Signature"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col sm="4" v-if="type_id == 1">
                    <v-checkbox
                      v-model="form.settings.hide_student_image"
                      label="Hide Student Image"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="warning"
              text
              @click="
                (form.dialog = false), form.reset(), (display_card_size = false)
              "
              >Cancel</v-btn
            >
            <v-btn outlined @click="save(false)" color="info" text>Save</v-btn>
            <v-btn
              v-if="type_id == 2 && form.name !== 'STAFF ID CARD'"
              @click="
                (selectedTemplateId = form.id_card_template_id), save(true)
              "
              outlined
              color="success"
              text
              >Save & Preview</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="content_dialogue">
        <v-card outlined class="primary--border">
          <v-card-title class="primary white--text">
            <span class="title">Add Certificate Content</span>
          </v-card-title>
          <v-card-text>
            <div class="mt-2">
              <v-row>
                <v-col>
                  <v-text-field
                    autocomplete="off"
                    label="Content Title *"
                    required
                    class="pa-0 mb-0"
                    outlined
                    dense
                    v-model="certificateContent.name"
                    name="name"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-template-creator-field
                    :rows="8"
                    :show-alert="false"
                    v-model="certificateContent.content"
                    label="Certificate Content *"
                    :default-placeholder="[
                      {
                        text: 'Student Name',
                        placeholder: '[[student_name]]',
                      },
                      {
                        text: 'Father Name',
                        placeholder: '[[father_name]]',
                      },
                      {
                        text: 'Mother Name',
                        placeholder: '[[mother_name]]',
                      },
                      {
                        text: 'District',
                        placeholder: '[[district]]',
                      },
                      {
                        text: 'Address',
                        placeholder: '[[address]]',
                      },
                      {
                        text: 'Ward No',
                        placeholder: '[[ward_no]]',
                      },
                      {
                        text: 'From Date',
                        placeholder: '[[from_date]]',
                      },
                      {
                        text: 'To Date',
                        placeholder: '[[to_date]]',
                      },
                      {
                        text: 'Grade',
                        placeholder: '[[grade]]',
                      },
                      {
                        text: 'GPA',
                        placeholder: '[[gpa]]',
                      },
                      {
                        text: 'Exam Date BS',
                        placeholder: '[[exam_date_BS]]',
                      },
                      {
                        text: 'Exam Date AD',
                        placeholder: '[[exam_date_AD]]',
                      },
                      {
                        text: 'Date Of Birth(BS)',
                        placeholder: '[[date_of_birth_BS]]',
                      },
                      {
                        text: 'Date Of Birth(AD)',
                        placeholder: '[[date_of_birth_AD]]',
                      },
                      {
                        text: 'Character',
                        placeholder: '[[character]]',
                      },
                      {
                        text: 'Religion',
                        placeholder: '[[religion]]',
                      },
                      {
                        text: 'Provience',
                        placeholder: '[[provience]]',
                      },
                    ]"
                    :default-content="certificateContent.content"
                  ></v-template-creator-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="warning"
              text
              @click="resetCertificateContent()"
              >Cancel</v-btn
            >
            <v-btn outlined color="info" @click="saveContent" text>Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- DELETE CERTIFICATE CONTENT -->
      <v-dialog
        v-model="listCertificateContent"
        scrollable
        persistent
        max-width="520px"
      >
        <v-card outlined class="primary--border">
          <v-card-title class="primary white--text">
            <span class="title">Delete Certificate Content</span>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="certificate_contents"
              :loading="form.loading"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ ++index }}</td>

                  <td class="text-xs-left">{{ item.name }} <br /></td>
                  <td class="text-right">
                    <delete-button
                      @agree="deleteCertificateContent(item.id)"
                      permission="assignment-delete"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="warning"
              text
              @click="listCertificateContent = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";
// import AddButton from "../../../../components/AddButton.vue";
export default {
  mixins: [Mixins],
  data: () => ({
    listCertificateContent: false,
    warning_type: "success",
    image_warning_type: "success",
    templates: [],
    content_dialogue: false,
    selectedTemplateId: null,
    content_body: [
      { text: "Name", value: "name" },

      { text: "Role", value: "role" },
      { text: "Email", value: "email"},
      { text: "Joined", value: "joined"},

      { text: "Enroll Code", value: "enroll_code" },
      { text: "Grade Section", value: "grade_section" },
      { text: "Roll No", value: "roll_no" },
      { text: "Contact Number", value: "contact_number" },
      { text: "Teaching Licence Number", value: "teaching_licence_number" },
      { text: "Driving Licence Number", value: "driving_licence_number" },
      { text: "Dob", value: "dob" },
      { text: "Blood Group", value: "blood_group" },
      { text: "Address", value: "address" },
    ],
    entrance_content_body: [
      { text: "Name", value: "name" },
      { text: "Enroll Code", value: "enroll_code" },
      { text: "Grade Section", value: "grade_section" },
      { text: "Roll No", value: "roll_no" },
      { text: "Blood Group", value: "blood_group" },
    ],
    signature: [
      { text: "Principal", value: "principal" },
      { text: "Exam-Cordinator", value: "exam-coordinator" },
      { text: "Accountant", value: "accountant" },
      { text: "Class Teacher", value: "class-teacher" },
      { text: "Co-ordinator", value: "co-ordinator" },
    ],
    footer_options:[
      {text:"Stamp", value:"stamp"},
      {text:"Date of Issue", value:"date_of_issue"},
      {text:"Checked By", value:"checked_by"},
      {text:"Prepared By", value:"prepared_by"},
    ],
    headers: [
      {
        text: "#",
        align: "left",
        value: "roll",
        width: 10,
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
      },
    ],
    signature_positions: [
      { text: "Top", value: "top" },
      { text: "Left", value: "left" },
    ],
    content_weight: [
      { text: "Bold", value: "bold" },
      { text: "Normal", value: "normal" },
    ],
    logo_position: [
      { text: "Left", value: "left" },
      { text: "Right", value: "right" },
      { text: "Top", value: "top" },
    ],
    font_family: [
      { text: "Normal", value: "normal" },
      { text: "Italic", value: "italic" },
    ],
    scan_type: [
      { text: "Barcode", value: "BARCODE" },
      { text: "QrCode", value: "QRCODE" },
      { text: "None", value: "NONE" },
    ],
    entrance_cards_display: [
      { text: "Both", value: "BOTH" },
      { text: "Entrance Card Only", value: "ENTRANCE" },
      { text: "Routine Only", value: "ROUTINE"},
    ],
    form: new Form(
      {
        font_family: "normal",
        logo_position: "left",
        content: "",
        created_at: "",
        id: "",
        name: "",
        settings: "",
        template_type_id: "",
        updated_at: "",
        selected: [],
        form_body: [],
        image_size: {
          height: "",
          width: "",
        },
        logo_size: {
          height: "",
          width: "",
        },
        color: {
          primary: "",
          secondary: "",
        },

        card_size: {
          height: "",
          width: "187",
        },
        slogan_font_size: "",
        content_weight: "",
        id_card_template_id: "",
        certificate_content_id: "",
        is_default: 0,
        note: "",
        signature_setting: {
          signature_width: "",
          signature_left: "",
          signature_top: "",
        },
        scan_type: "BARCODE",
        display_entrance_card:"BOTH",
        display_signature:0,
        certificate_footer_options:[],
        gutter_space:0,
        hide_student_image:0,
      },
      "/api/template"
    ),
    certificateContent: {
      id: "",
      name: "",
      content: "",
      company_template_id: "",
    },
    optionParam: true,
    certificate_contents: [],
    certificate_dropdown_contents: [],
    display_card_size: false,
  }),

  mounted() {
    this.get();
    if (this.type_id == 1) this.getCertificateContent();
  },
  props: {
    type_id: {
      default: 1,
    },
    title: {
      default: "Certificate Template",
    },
    type: {
      default: "CERTIFICATE",
    },
  },
  computed: {
    selectAllFormBody() {
      let selectedAll = "";
      if (this.type != "CERTIFICATE") {
        selectedAll =
          this.form.settings.card_body.content === this.form.form_body;
      }
      return selectedAll;
    },
    selectFewFormBody() {
      let selectedFew = "";
      if (this.type != "CERTIFICATE") {
        selectedFew =
          this.form.settings.card_body.content > 0 && !this.form.form_body;
      }
      return selectedFew;
    },

    icon() {
      if (this.selectAllFormBody) return "check_box";
      if (this.selectFewFormBody) return "indeterminate_check_box";
      return "add_box";
    },
    selectedAllSignature() {
      return this.signature === this.form.selected;
    },
    selectedFewSignature() {
      return this.signature > 0 && !this.form.selected;
    },
    signatureIcon() {
      if (this.selectedAllSignature) return "check_box";
      if (this.selectedFewSignature) return "indeterminate_check_box";
      return "add_box";
    },

    selectedAllFooterOption(){
      return this.footer_options === this.form.certificate_footer_options;

    },

     selectedFewFooterOption() {
      return this.footer_options > 0 && !this.form.certificate_footer_options;
    },
    footerOptionIcon() {
      if (this.selectedAllFooterOption) return "check_box";
      if (this.selectedFewFooterOption) return "indeterminate_check_box";
      return "add_box";
    },
  },
  methods: {
    deleteCertificateContent(id) {
      this.$rest
        .delete("/api/delete-certificate-content/" + id)
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.listCertificateContent = false
          this.getCertificateContent();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
          this.listCertificateContent = false

        });
    },
    getCertificateContent() {
      this.$rest
        .get("/api/get-certificate-content")
        .then((res) => {
          if (res.data.length > 0) {
            this.form.content = "";
            this.certificate_contents = res.data;
            this.certificate_dropdown_contents = res.data.map((item) => {
              return { value: item.id, text: item.name };
            });
            res.data.filter((item) => {
              if ((item.status == 1 && item.id == 1) || item.status == 1) {
                this.certificateContent.id = item.id;
                this.form.content = item.content;
                this.form.is_default = item.status == 1 ? true : 0;
              }
            });
          }
        })
        .catch((e) => {});
    },
    saveContent() {
      if (
        this.certificateContent.name == "" ||
        this.certificateContent.content == ""
      ) {
        this.$events.fire("notification", {
          message: "Please fill the required field ",
          status: "error",
        });
        return;
      } else {
        this.$rest
          .post("/api/save-certificate-content", {
            data: this.certificateContent,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$events.fire("notification", {
                message: res.data.message,
                status: "success",
              });
              this.resetCertificateContent();
              this.get();
              this.getCertificateContent();
            }
          })
          .catch((e) => {});
      }
    },
    resetCertificateContent() {
      this.content_dialogue = false;
      this.certificateContent.name = "";
      this.certificateContent.content = "";
    },

    reset() {
      this.form.content = "";
      this.form.id = "";
      this.form.name = "";
      this.form.settings = "";
      this.form.template_type_id = "";
      this.form.updated_at = "";
      this.form.selected = [];
      this.form.form_body = [];
      this.form.image_size.height = "";
      this.form.image_size.width = "";
      this.form.logo_size.height = "";
      this.form.logo_size.width = "";
      this.form.logo_position = "";
      this.form.color.primary = "";
      this.form.color.secondary = "";
      this.form.color.content_weight = "";
      this.form.color.id_card_template_id = 1;
      this.templates = [];
      this.form.font_family = "normal";
      this.form.card_size.height = "306.3";
      this.form.card_size.width = "187";
      this.display_card_size = false;
      this.display_entrance_card = "BOTH";
      this.form.certificate_footer_options = [];

      // this.form.is_default = 0;
    },
    changeDetect() {
      if (this.form.logo_size.height == this.form.logo_size.width) {
        this.warning_type = "success";
      } else {
        this.warning_type = "warning";
      }
    },
    changeImageDetech() {
      if (this.form.image_size.height == this.form.image_size.width) {
        this.image_warning_type = "success";
      } else {
        this.image_warning_type = "warning";
      }
    },
    get() {
      let query = this.queryString("template_type=" + this.type);
      this.form
        .get(null, query)
        .then((res) => {
          this.templates = res.data;
          this.form.selected = res.data[0].settings.signature;
          this.form.logo_position = res.data[0].settings.logo_position;
          this.form.font_family = res.data[0].settings.font_family;
          this.form.note = res.data[0].settings.note;
          this.form.content_weight =
            res.data[0].settings.card_body.content_weight;
        })
        .catch((e) => {});
    },
    save(preview = false) {
      if (this.form.selected <= 0 && this.type_id == 1) {
        this.$events.fire("notification", {
          message: "Please Select at least one signature from the list ",
          status: "error",
        });
        return;
      }
      if (this.form.certificate_footer_options <= 0 && this.type_id == 1) {
        this.$events.fire("notification", {
          message: "Please Select at least one footer option from the list ",
          status: "error",
        });
        return;
      }
      this.$rest
        .post("/api/store-template", {
          data: this.form,
        })
        .then((res) => {
          if (res.status == 200) {
            this.form.dialog = false;
            this.$refs.form.reset();
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
            this.getCertificateContent();
            if (preview) {
              this.printSingle();
            }
          }
        })
        .catch((e) => {});
    },
    toggle() {
      this.$nextTick(() => {
        if (this.form.form_body.length > 0) {
          this.form.form_body = [];
        } else {
          this.form.form_body = this.form.settings.card_body.content;
        }
      });
    },
    toogleSignature() {
      this.$nextTick(() => {
        if (this.form.selected.length > 0) {
          this.form.selected = [];
        } else {
          this.form.selected = this.signature;
        }
      });
    },
    toogleFooterOption() {
      this.$nextTick(() => {
        if (this.form.certificate_footer_options.length > 0) {
          this.form.certificate_footer_options = [];
        } else {
          this.form.certificate_footer_options = this.footer_options;
        }
      });
    },
    printSingle() {
      this.$rest.get("/api/print/student-id-card").then(({ data }) => {
        let url =
          data.data.download_url +
          "?preview=true" +
          "&id_card_template_id=" +
          this.selectedTemplateId;
        window.open(url);
        this.selectedTemplateId = null;
      });
    },
  },
  watch: {
    "certificateContent.id": function (val) {
      this.certificate_contents.filter((item) => {
        if (item.id == val) {
          this.form.certificate_content_id = val;
          this.form.content = item.content;
          this.form.is_default = item.status == 1 ? true : 0;
          this.form.display_signature = item.display_signature == 1 ? true : 0;
          this.form.hide_student_image = item.hide_student_image == 1 ? true : 0;
        }
      });
    },
    "form.settings": function (val) {
      if (this.form.settings != "") {
        if(this.type_id == 1){
          this.form.certificate_footer_options = val.certificate_footer_options;
        }
        this.form.image_size.height = val.image_size.height;
        this.form.image_size.width = val.image_size.width;
        if (this.type_id == 2 || this.type_id == 1) {
          this.form.logo_size.height = val.logo_size.height;
          this.form.logo_size.width = val.logo_size.width;
        }
        if (this.type_id == 2 || this.type_id == 3) {
          this.form.form_body = val.card_body.content;
        }
        if(this.type_id == 3){
          if(val.display_entrance_card){
            this.form.display_entrance_card = val.display_entrance_card
          }
        }
        if (this.type_id == 2) {
          this.form.id_card_template_id = val.id_card_template_id;

          this.form.color.primary = val.color.primary;
          this.form.color.secondary = val.color.secondary;
          this.form.selected = val.signature;

          if (
            parseInt(val.id_card_template_id) == 1 ||
            parseInt(val.id_card_template_id) == 2 ||
            parseInt(val.id_card_template_id) == 3
          ) {
            this.display_card_size = true;
          }
          if (val.signature_setting) {
            this.form.signature_setting = val.signature_setting;
          } else {
            this.form.signature_setting.signature_top = 100;
            this.form.signature_setting.signature_left = 100;
            this.form.signature_setting.signature_width = 200;
          }
          if (val.card_size) {
            this.form.card_size = val.card_size;
          } else {
            this.form.card_size.height = 306.3;
            this.form.card_size.width = 187;
          }
          this.form.scan_type = val.scan_type ?? "BARCODE";
        }
      }
    },
  },
};
</script>
